// Begin Loading
.loading {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	z-index: 9999;
}
.loading .loading-block {
	width: 100%;
	height: 2px;
	position: relative;
	top: 0;
	margin: 0 auto;
}
@keyframes customWidthAnimation {
	0% {
		width: 0;
	}
	100% {
		width: 99%;
	}
}
.loading-progress{
	background-color: #45BF5D;
	width: 0;
	height: 2px;
	animation: customWidthAnimation 2s forwards;
}

.box-image-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
	flex-wrap: wrap;
}

.box-image-loading .doctor-loading {
  width: 200px;
  height: 250px;
	padding: 0;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	text-align: center;
}

.image-loading{
	width: 150px;
  height: 150px;
}

.image-process{
	width: 100px;
	height: 8px;
}

.time-number{
	font-weight: bold;
	display: none;
}

.hospital-progress{
	text-align: center;
	color: #45BF5D;
}
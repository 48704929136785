.slider-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slider {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  transition: transform 0.5s ease-in-out;
  row-gap: 2px;
  column-gap: 2px;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.5;
  color: #ffffff;
  background-color: black;
  padding: 5px;
}

.nav-button:hover{
  opacity: 1;
}

#prevButton {
  left: 0px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

#nextButton {
  right: 0px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}